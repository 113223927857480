import React, { useState } from "react"
import styled from "styled-components"
import { Col, Typography, Row } from "antd"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../../htmlSerializer"
import Carousel from "../carousel"
import Form from "../formulaire"
import { Section } from "../stylingTools"

const Soutenir = ({ content }) => {
  const [mailStatus, setStatus] = useState("")
  const [loading, setLoading] = useState(false)

  const onSubmit = async values => {
    setLoading(true)
    const response = await fetch("/.netlify/functions/sendMail", {
      method: "POST",
      body: JSON.stringify(values),
    })
    setLoading(false)
    setStatus(response.status === 200 ? "success" : "failure")
  }

  //   const soutienElements = content.s8_repeteur.map(soutienElement => {
  //     const logo = <img src={soutienElement.s8_logo.url} />
  //     return {
  //       logo: logo,
  //       url: soutienElement.lien?.url,
  //       text: soutienElement.s8_information,
  //     }
  //   })

  //   const collectifElements = content.s9_repeteur.map(collectifElement => {
  //     const logo = <img src={collectifElement.s9_logo.url} />
  //     return {
  //       logo: logo,
  //       url: collectifElement.lien?.url,
  //       text: collectifElement.s9_information,
  //     }
  //   })

  return (
    <Section>
      <div className="myContainer">
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <RichText
              render={content.s7_contenu.raw}
              htmlSerializer={htmlSerializer}
            />
          </Col>
          <Form
            onSubmit={onSubmit}
            mailStatus={mailStatus}
            loading={loading}
            label1={content.s7_label_1.text}
            label2={content.s7_label_2.text}
          />
        </Row>
      </div>
      <CarouselWrapper>
        <RichText
          render={content.s8_contenu.raw}
          htmlSerializer={htmlSerializer}
        />
        <Carousel items={content.s8_repeteur} />
      </CarouselWrapper>

      <CarouselWrapper>
        <RichText
          render={content.s9_contenu.raw}
          htmlSerializer={htmlSerializer}
        />
        <Carousel items={content.s9_repeteur} />
      </CarouselWrapper>
    </Section>
  )
}

const CarouselWrapper = styled.div`
  max-width: 1250px;
  margin: 4rem auto;
  text-align: center;

  @media (min-width: 700px) and (max-width: 1099px) {
    margin: 4rem 5rem;
  }
`
export default Soutenir
