import React from "react"
import styled from "styled-components"
import { Col, Typography, Row } from "antd"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../../htmlSerializer"
import Carousel from "../carousel"
import { Section } from "../stylingTools"

function Collectif({ content }) {
  return (
    <Section style={{ paddingBottom: 0 }}>
      <div className="myContainer">
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <RichText
              render={content.s5_contenu.raw}
              htmlSerializer={htmlSerializer}
            />
          </Col>
        </Row>
      </div>
      <CarouselWrapper>
        <Carousel items={content.s5_membres} />
      </CarouselWrapper>
    </Section>
  )
}

const CarouselWrapper = styled.div`
  max-width: 1250px;
  margin: 4rem auto;

  @media (min-width: 700px) and (max-width: 1099px) {
    margin: 4rem 5rem;
  }
`

export default Collectif
