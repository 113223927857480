import styled from "styled-components"
export const Section = styled.div`
  padding: 75px 0;
`

export const MainGrid = styled.div`
  display grid;
  @media (max-width: 699px) {
    grid-template-columns: 10px auto 10px;
    grid-template-areas: ". wrapper ."
  }

  @media (min-width: 700px) {
    grid-template-columns: 30px auto 30px;
    grid-template-areas: ". wrapper ."
  }
  @media (min-width: 1100px) {
    grid-template-columns: auto 1050px auto;
    grid-template-areas: ". wrapper ."
  }
`

export const InnerGrid = styled.div`
  grid-area: wrapper;

  @media (min-width: 1100px) {
    display: grid;
    grid-template-columns: 43% 43%;
    grid-column-gap: 14%;
  }
`

export const Text = styled.div`
  letter-spacing: 0.5pt;
  font-weight: 400;

  p {
    margin-block-start: 2em;
    margin-block-end: 2em;
  }
`

export const MainTitle = styled.div`
  color: #000;
  font-size: 34px;
  line-height: 41px;
  font-family: Century_Gothic;
  padding-top: 75px;
  padding-bottom: 1.5rem;
  text-align: center;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
`

export const Content = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 0.4em;
  text-align: justify;
  align-content: start;
  color: black;
  font-size: 18px;
`
