import React from "react"
import styled from "styled-components"
import { Row, Col, Typography } from "antd"

function Footer() {
  return (
    <FooterWrapper>
      <div className="myContainer">
        <Row>
          <Col span={24}>
            <Typography.Paragraph style={{ padding: "10px 0 0 0" }}>
              CODE 1 © 2020 — Tous droits réservés
            </Typography.Paragraph>
          </Col>
        </Row>
      </div>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  background: rgb(36, 182, 174);
  text-align: center;
  .ant-typography {
    color: #fff;
  }
`

export default Footer
