import React from "react"
import { Link } from "react-scroll"
import { Menu, Dropdown } from "antd"
import styled from "styled-components"
import { DownOutlined } from "@ant-design/icons"

const menu = (
  <Menu>
    <Menu.Item>
      <Link
        activeClass="active"
        to="demain"
        smooth={true}
        spy={true}
        offset={-75}
      >
        Un projet de société
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link
        activeClass="active"
        to="programme"
        smooth={true}
        spy={true}
        offset={-75}
      >
        Un programme d'innovation
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link
        activeClass="active"
        to="reve"
        smooth={true}
        spy={true}
        offset={-75}
      >
        Un rêve
      </Link>
    </Menu.Item>
  </Menu>
)
const dropdownStyle = {
  zIndex: 9999,
}
function Links() {
  return (
    <>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        overlayStyle={dropdownStyle}
      >
        <StyledLink
          style={{ marginLeft: "2.5rem", cursor: "pointer" }}
          activeClass="active"
          to="introduction"
          smooth={true}
          spy={true}
          offset={-75}
        >
          Qu'est-ce que code 1 ? <DownOutlined />
        </StyledLink>
      </Dropdown>
      <StyledLink
        style={{ marginLeft: "2.5rem", cursor: "pointer" }}
        activeClass="active"
        to="collectif"
        smooth={true}
        spy={true}
        offset={-75}
      >
        Le collectif
      </StyledLink>
      <StyledLink
        style={{ marginLeft: "2.5rem", cursor: "pointer" }}
        activeClass="active"
        to="soutenir"
        smooth={true}
        spy={true}
        offset={-75}
      >
        Nous soutenir
      </StyledLink>
    </>
  )
}

const StyledLink = styled(Link)`
  color: #24b6ae;
  position: relative;
  padding: 10px 0;
  &:hover {
    color: #24b6ae;
  }
  @media (min-width: 1100px) {
    &::after {
      content: "";
      bottom: -4px;
      left: calc(50% - 18px);
      width: 35px;
      height: 2px;
      position: absolute;
      transition: transform 0.4s;
      background: #24b6ae;
      transform: scale(0);
    }
    &:hover::after,
    &.active::after {
      transform: scale(1);
    }
  }
`

export default Links
