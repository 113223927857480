import React from "react"
import styled from "styled-components"
import { Typography } from "antd"
import { Link } from "react-scroll"
import { useStaticQuery, graphql } from "gatsby"

import Hero from "../images/hero"
import Logo from "../images/logo"

function HeroSection({ reference, content }) {
  const { Title } = Typography

  return (
    <div ref={reference} style={{ height: "100vh" }}>
      <Hero>
        <HeroTitle>
          <Top>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Title>{content.titre.text}</Title>
          </Top>

          <Bottom>
            <Title strong level={2}>
              {content.texte.text}
            </Title>
          </Bottom>
        </HeroTitle>
        <GoDown to="introduction" smooth={true} spy={true}>
          ⌄
        </GoDown>
        <HeroFilter />
      </Hero>
    </div>
  )
}

const LogoWrapper = styled.div`
  width: 200px;
  @media (max-width: 699px) {
    width: 120px;
  }
  @media (max-width: 399px) {
    width: 90px;
  }
`
const GoDown = styled(Link)`
  cursor: pointer;
  color: #fff;
  z-index: 9;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 75px;
  transition: color 0.4s;
  &:hover {
    color: #24b6ae;
  }
`

const Top = styled.div`
  text-align: center;
  font-size: 5rem;
  font-weight: 900;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  @media (max-width: 699px) {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  @media (max-width: 399px) {
    font-size: 2.2rem;
    margin-bottom: 2rem;
  }
`

const Bottom = styled.div`
  text-align: center;

  @media (max-width: 699px) {
    font-size: 2rem;
  }
  @media (max-width: 399px) {
    font-size: 1.5rem;
  }
`

const HeroTitle = styled.div`
  z-index: 9;
  position: relative;
  font-family: Century_Gothic_Bold;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-width: 600px;
  margin: auto;
  h1 {
    color: white;
    font: Bold 80px/98px Century Gothic;
    margin-bottom: 0;
    @media (max-width: 789px) {
      font: Bold 60px/98px Century Gothic;
    }
  }
  h2 {
    color: white;
  }
`
const HeroFilter = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2));
  z-index: 0;
`

export default HeroSection
