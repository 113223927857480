import React from "react"
import styled from "styled-components"
import { Carousel } from "antd"
import uuid from "react-uuid"
import Img from "gatsby-image"

import { RichText } from "prismic-reactjs"
import htmlSerializer from "../htmlSerializer"

function ControlledCarousel({ items }) {
  const props = {
    dots: true,
    infinite: true,
    speed: 750,
    autoplay: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrow: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }

  return (
    <>
      {items.length > 4 ? (
        <Carousel {...props} autoplay>
          {items.map(item => (
            <div key={uuid()} style={{ textAlign: "center" }}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.logo1.localFile.childImageSharp ? (
                  <Logo>
                    <Img fixed={item.logo1.localFile.childImageSharp.fixed} />
                  </Logo>
                ) : (
                  <Logo>
                    <img src={item.logo1.url} />
                  </Logo>
                )}
                {item.information && (
                  <Infos>
                    <RichText
                      render={item.information.raw}
                      htmlSerializer={htmlSerializer}
                    />
                  </Infos>
                )}
              </a>
            </div>
          ))}
        </Carousel>
      ) : (
        <DisplayWithoutCarousel>
          {items.map(item => (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              key={uuid()}
              style={{ textAlign: "center" }}
            >
              {item.logo1 && (
                <Logo>
                  <Img fixed={item.logo1.localFile.childImageSharp.fixed} />
                </Logo>
              )}
              {item.information && (
                <Infos>
                  <RichText
                    render={item.information.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </Infos>
              )}
            </a>
          ))}
        </DisplayWithoutCarousel>
      )}
    </>
  )
}

const Infos = styled.div`
  text-align: center;
  color: #000;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    margin-bottom: 5px;
    &:nth-child(1) {
      font-weight: bold;
    }
  }
`
const Logo = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-height: 100%;
    max-width: 100%;
    width: 100px;
  }
`
const DisplayWithoutCarousel = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  a {
    width: 300px;
  }
`

export default ControlledCarousel
