import React from "react"
import { Element } from "react-scroll"
import { StaticQuery, graphql } from "gatsby"

import Hero from "../components/sections/hero"
import Navbar from "../components/navbar"
import SEO from "../components/seo"
import Presentation from "../components/sections/presentation"
import Demain from "../components/sections/demain"
import Definitions from "../components/sections/definitions"
import ReveFrancais from "../components/sections/reveFrancais"
import Collectif from "../components/sections/collectif"
import Quote from "../components/quote"
import Soutenir from "../components/sections/soutenir"
import Footer from "../components/footer"

const IndexPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query Homepage {
        prismicHomepage {
          seo: data {
            meta_description
            meta_title
          }
          hero: data {
            texte {
              text
            }
            titre {
              text
            }
          }
          presentation: data {
            s1_bouton {
              url
              target
            }
            s1_contenu {
              raw
            }
            s1_repeteur {
              s1_repeteur_contenu {
                raw
              }
            }
          }
          demain: data {
            s2_contenu {
              raw
            }
            s2_embed {
              embed_url
              title
            }
          }
          definitions: data {
            s3_contenu {
              raw
            }
            s3_repeteur {
              s3_repeteur_contenu {
                raw
              }
            }
          }
          reve: data {
            s4_arriere_plan {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1660, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
            s4_contenu {
              raw
            }
            s4_image_float {
              localFile {
                childImageSharp {
                  fixed(height: 200, quality: 90) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
                }
              }
            }
            s4_titre1 {
              raw
            }
          }
          collectif: data {
            s5_contenu {
              raw
            }
            s5_membres {
              lien {
                url
              }
              information {
                raw
              }
              logo1 {
                url
                localFile {
                  childImageSharp {
                    fixed(width: 150, quality: 90) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
          quote: data {
            s6_contenu {
              raw
            }
            s6_arriere_plan {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1660, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
          soutenir: data {
            s7_contenu {
              raw
            }
            s7_label_1 {
              text
            }
            s7_label_2 {
              text
            }
            s8_contenu {
              raw
            }
            s8_repeteur {
              lien {
                link_type
                url
              }
              information {
                raw
              }
              logo1 {
                url
                localFile {
                  childImageSharp {
                    fixed(width: 150, quality: 90) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            s9_contenu {
              raw
            }
            s9_repeteur {
              lien {
                url
              }
              information {
                raw
              }
              logo1 {
                url
                localFile {
                  childImageSharp {
                    fixed(width: 150, quality: 90) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div location={location}>
        <SEO
          title={data.prismicHomepage.seo.meta_title}
          description={data.prismicHomepage.seo.meta_description}
        />
        <Navbar />
        <Element name="header">
          <Hero content={data.prismicHomepage.hero} />
        </Element>
        <Element name="introduction">
          <Presentation content={data.prismicHomepage.presentation} />
        </Element>
        <Element name="demain">
          <Demain content={data.prismicHomepage.demain} />
        </Element>
        <Element name="programme">
          <Definitions content={data.prismicHomepage.definitions} />
        </Element>
        <Element name="reve">
          <ReveFrancais content={data.prismicHomepage.reve} />
        </Element>
        <Element name="collectif">
          <Collectif content={data.prismicHomepage.collectif} />
          <Quote content={data.prismicHomepage.quote} />
        </Element>

        <Element name="soutenir">
          <Soutenir content={data.prismicHomepage.soutenir} />
        </Element>
        <Footer />
      </div>
    )}
  />
)

export default IndexPage
