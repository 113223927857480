import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-background-image"

const Image = ({ children }) => {
  const data = useStaticQuery(graphql`
    query Img {
      hero: file(relativePath: { eq: "images/hero.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.hero.childImageSharp.fluid}
      style={{ height: "100%" }}
      imgStyle={{ objectFit: "cover" }}
      preserveStackingContext
    >
      {children}
    </Img>
  )
}

export default Image
