import React from "react"
import styled from "styled-components"
import { Row, Col, Typography } from "antd"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../../htmlSerializer"
import { Section } from "../stylingTools"
import Square from "../square"

const Definitions = ({ content }) => {
  const definitions = content.s3_repeteur.map((value, index) => {
    return {
      content: value.s3_repeteur_contenu.raw,
      id: "acebd".charAt(index),
      key: index,
    }
  })

  return (
    <Section>
      <div className="myContainer">
        <Row>
          <Col span={24}>
            <RichText
              render={content.s3_contenu.raw}
              htmlSerializer={htmlSerializer}
            />
          </Col>
        </Row>
        <SquareGrid>
          {definitions.map(square => (
            <Square {...square} />
          ))}
        </SquareGrid>
      </div>
    </Section>
  )
}
const SquareGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .a {
    border-right: 2px solid #8ec648;
    border-top: 2px solid #8ec648;
  }
  .b {
    border-right: 2px solid #f2bb5c;
    border-bottom: 2px solid #f2bb5c;
  }
  .c {
    border-right: 2px solid #c1252d;
    border-top: 2px solid #c1252d;
  }
  .d {
    border-right: 2px solid #d82781;
    border-bottom: 2px solid #d82781;
  }
  .e {
    border-right: 2px solid #f0cd3b;
    border-top: 2px solid #f0cd3b;
  }
`

export default Definitions
