import React from "react"
import styled from "styled-components"
import { Typography } from "antd"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../htmlSerializer"

const Square = ({ content, id }) => {
  return (
    <SquareWrapper style={{ gridArea: id }} className={id}>
      <RichText render={content} htmlSerializer={htmlSerializer} />
    </SquareWrapper>
  )
}

const SquareWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: calc(33% - 70px);
  margin: 35px;
  @media (max-width: 1099px) {
    width: calc(33% - 30px);
    margin: 15px;
  }
  @media (max-width: 789px) {
    width: calc(50% - 20px);
    margin: 10px;
  }
  @media (max-width: 580px) {
    width: 100%;
    margin: 20px 20px;
  }
`

export default Square
