import React, { useState } from "react"
import styled from "styled-components"
import {
  Form,
  Input,
  Button,
  Radio,
  Checkbox,
  Typography,
  Row,
  Col,
} from "antd"

import PDF from "../files/pdf/mentions-legales.pdf"

const ItemWithLabel = ({ name, label, required, area }) => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    <div>{label}</div>
    <Form.Item name={name} rules={[{ required, message: " " }]}>
      {area ? <Input.TextArea /> : <Input />}
    </Form.Item>
  </div>
)

const FormWrapper = ({ label1, label2, onSubmit, mailStatus, loading }) => {
  const [engager, setEngager] = useState(false)

  const onValuesChange = ({ soutien }) => {
    if (soutien === "soutenir") setEngager(false)
    else if (soutien === "engager") setEngager(true)
  }

  const { Text } = Typography
  return (
    <Form
      name="basic"
      initialValues={{
        soutien: "soutenir",
      }}
      onFinish={onSubmit}
      onValuesChange={onValuesChange}
      style={{ width: "100%", marginTop: 50 }}
    >
      <Row>
        <Col xs={24} md={10}>
          <RadioSection>
            <Form.Item
              name="soutien"
              rules={[{ required: true, message: " " }]}
            >
              <Radio.Group>
                <Radio value="soutenir">{label1}</Radio>
                <Radio value="engager">{label2}</Radio>
              </Radio.Group>
            </Form.Item>
          </RadioSection>
        </Col>

        <Col xs={24} md={14}>
          <ItemWithLabel name="prenom" label="Prénom *" required />
          <ItemWithLabel name="nom" label="Nom *" required />
          <ItemWithLabel name="mail" label="Email *" required />
          {engager && (
            <>
              <ItemWithLabel
                name="entity"
                label="Entité * (Entreprises, Associations, Réseaux …)"
                required
              />
              <ItemWithLabel name="fonction" label="Fonction *" required />
            </>
          )}
        </Col>
      </Row>

      <Form.Item
        name="allow"
        valuePropName="checked"
        rules={[{ required: true, message: " " }]}
      >
        <Checkbox>
          J’accepte les
          <Text style={{ fontStyle: "italic", marginTop: "0.5rem" }}>
            <a href={PDF}>
              {" "}
              conditions d’utilisation et politique de confidentialité
            </a>
          </Text>{" "}
          de CODE 1
        </Checkbox>
      </Form.Item>

      <Form.Item
        name="allowBis"
        valuePropName="checked"
        rules={[{ required: true, message: " " }]}
      >
        <Checkbox>
          J'accepte que mon nom apparaisse sur le site de CODE 1
        </Checkbox>
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        disabled={mailStatus === "success"}
        style={{ color: "white" }}
      >
        {!loading && mailStatus !== "success" ? "Envoyer" : ""}
        {loading ? "Envoi en cours" : ""}
        {mailStatus === "success" ? "Message envoyé" : ""}
      </Button>
      <div style={{ marginLeft: "1rem", display: "inline-block" }}>
        {mailStatus === "failure" ? "Echec de l’envoi" : ""}
      </div>
    </Form>
  )
}

const RadioSection = styled.div`
  @media (min-width: 700px) {
    max-width: 350px;
  }
`

export default FormWrapper
