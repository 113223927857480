import React from "react"
import { Elements } from "prismic-reactjs"

const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key })
}

const htmlSerializer = function(type, element, content, children, key) {
  var props = {}
  switch (type) {
    case Elements.heading1: // Heading 1
      props = { className: "ant-typography" }
      return React.createElement("h1", propsWithUniqueKey(props, key), children)

    case Elements.heading2: // Heading 2
      props = { className: "ant-typography" }
      return React.createElement("h2", propsWithUniqueKey(props, key), children)

    case Elements.heading3: // Heading 3
      props = { className: "ant-typography" }
      return React.createElement("h3", propsWithUniqueKey(props, key), children)

    case Elements.heading4: // Heading 4
      props = { className: "ant-typography" }
      return React.createElement("h4", propsWithUniqueKey(props, key), children)

    case Elements.heading5: // Heading 5
      props = { className: "ant-typography" }
      return React.createElement("h5", propsWithUniqueKey(props, key), children)

    case Elements.heading6: // Heading 6
      props = { className: "ant-typography" }
      return React.createElement("h6", propsWithUniqueKey(props, key), children)

    case Elements.paragraph:
      props = { className: "ant-typography" }
      return React.createElement("p", propsWithUniqueKey(props, key), children)

    case Elements.preformatted: // Preformatted
      return React.createElement(
        "pre",
        propsWithUniqueKey(props, key),
        children
      )

    case Elements.strong: // Strong
      return React.createElement(
        "strong",
        propsWithUniqueKey(props, key),
        children
      )

    case Elements.image:
      props = {
        src: element.url,
        alt: element.alt || "",
        className: "img-inline",
      }
      return React.createElement("img", propsWithUniqueKey(props, key))

    case Elements.em: // Emphasis
      return React.createElement("em", propsWithUniqueKey(props, key), children)

    case Elements.listItem: // Unordered List Item
      return React.createElement("li", propsWithUniqueKey(props, key), children)

    case Elements.oListItem: // Ordered List Item
      return React.createElement("li", propsWithUniqueKey(props, key), children)

    case Elements.list: // Unordered List
      return React.createElement("ul", propsWithUniqueKey(props, key), children)

    case Elements.oList: // Ordered List
      return React.createElement("ol", propsWithUniqueKey(props, key), children)

    // Return null to stick with the default behavior
    default:
      return null
  }
}

export default htmlSerializer
