import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Popover } from "@material-ui/core"
import { Link } from "react-scroll"

import Links from "./links"

function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null)

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Wrapper>
      <MobileMenu>
        <Link
          style={{ padding: "0 20px", cursor: "pointer" }}
          to="header"
          smooth={true}
          spy={true}
        >
          <img
            src={data.logo.childImageSharp.fixed.src}
            style={{ height: 50 }}
            alt="logo"
          />
        </Link>
        <button
          style={{ padding: "0 20px", zIndex: 1000 }}
          className={`hamburger hamburger--squeeze ${open ? "is-active" : ""}`}
          type="button"
          onClick={handleClick}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={{ square: true }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div style={{ width: 250, display: "flex", flexDirection: "column" }}>
            <Links />
          </div>
        </Popover>
      </MobileMenu>
      <DesktopMenu>
        <Link
          style={{ cursor: "pointer" }}
          to="header"
          smooth={true}
          spy={true}
        >
          <img
            src={data.logo.childImageSharp.fixed.src}
            style={{ height: 50 }}
            alt="logo"
          />
        </Link>
        <DesktopLinks>
          <Links />
        </DesktopLinks>
      </DesktopMenu>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  z-index: 50;
  background: #fff;
  color: #24b6ae;
  position: fixed;
  width: 100%;
  height: 70px;
  top: 0px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.33);

  @media (min-width: 1100px) {
    padding: 0px 70px;
  }

  @media (min-width: 1300px) {
    padding: 0px 140px;
  }
`
const DesktopMenu = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;

  @media (max-width: 1099px) {
    display: none;
  }

  .is-active {
    border-bottom: solid 2px white;
  }
`
const DesktopLinks = styled.div`
  display: flex;
`

const MobileMenu = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;

  @media (min-width: 1100px) {
    display: none;
  }
`

export default Navbar
