import React from "react"
import styled from "styled-components"
import { Typography, Row, Col } from "antd"
import { useStaticQuery, graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../../htmlSerializer"
import { Section } from "../stylingTools"

const Demain = ({ content }) => {
  const { Title, Paragraph } = Typography
  return (
    <DemainWrapper>
      <Section>
        <div className="myContainer">
          <Row>
            <Col span={24}>
              <RichText
                render={content.s2_contenu.raw}
                htmlSerializer={htmlSerializer}
              />
            </Col>
          </Row>
          <iframe
            title={content.s2_embed.title}
            frameBorder="0"
            style={{
              display: "block",
              width: "700px",
              height: "470px",
              margin: "50px auto auto auto",
            }}
            src={content.s2_embed.embed_url}
            type="text/html"
            allowscriptaccess="always"
            allowFullScreen={true}
            scrolling="yes"
            allownetworking="all"
          ></iframe>
        </div>
      </Section>
    </DemainWrapper>
  )
}

const DemainWrapper = styled.div`
  background: #24b6ae;
  .ant-typography {
    color: #fff;
  }
  @media (max-width: 789px) {
    iframe {
      width: 100% !important;
      height: 450px !important;
    }
  }
  @media (max-width: 512px) {
    iframe {
      height: 250px !important;
    }
  }
`

export default Demain
