import React from "react"
import { RichText } from "prismic-reactjs"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

import htmlSerializer from "../htmlSerializer"

const Quote = ({ content }) => {
  const data = useStaticQuery(graphql`
    query {
      quoteIcon: file(relativePath: { eq: "images/quote.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      fluid={content.s6_arriere_plan.localFile.childImageSharp.fluid}
    >
      <QuoteDiv>
        <QuoteWrapper>
          <div style={{ position: "absolute", width: 75, top: -45, left: -30 }}>
            <Img fluid={data.quoteIcon.childImageSharp.fluid} />
          </div>
          <RichText
            render={content.s6_contenu.raw}
            htmlSerializer={htmlSerializer}
          />
          <div
            style={{ position: "absolute", width: 75, bottom: 3, right: -35 }}
          >
            <Img fluid={data.quoteIcon.childImageSharp.fluid} />
          </div>
        </QuoteWrapper>
      </QuoteDiv>
    </BackgroundImage>
  )
}

const QuoteWrapper = styled.div`
  padding: 28px 0;
  position: relative;
  max-width: 40rem;
  .ant-typography {
    color: #fff;
  }
  @media (max-width: 1099px) {
    margin: 0 30px;
  }
`
const QuoteDiv = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  padding: 5rem 0 2rem 0;
  background: rgba(36, 182, 174, 0.8);
  color: #fff;
  text-align: justify;
  font-family: Century_Gothic;
  font-size: 1rem;

  @media (max-width: 699px) {
    padding: 4rem 0.7rem 0 0.7rem;
  }
`

export default Quote
