import React from "react"
import styled from "styled-components"
import { Row, Col, Typography } from "antd"
import { RichText } from "prismic-reactjs"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import htmlSerializer from "../../htmlSerializer"
import { Section } from "../stylingTools"

const ReveFrancais = ({ content }) => {
  return (
    <BackgroundImage
      fluid={content.s4_arriere_plan.localFile.childImageSharp.fluid}
    >
      <Section>
        <ReveWrapper>
          <div className="myContainer">
            <Row>
              <Col span={24}>
                <RichText
                  render={content.s4_titre1.raw}
                  htmlSerializer={htmlSerializer}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                md={12}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <RichText
                  render={content.s4_contenu.raw}
                  htmlSerializer={htmlSerializer}
                />
              </Col>
              <Col xs={24} md={12}>
                <Img
                  style={{ margin: "auto", display: "block" }}
                  fixed={content.s4_image_float.localFile.childImageSharp.fixed}
                />
              </Col>
            </Row>
          </div>
        </ReveWrapper>
      </Section>
      <ReveFilter />
    </BackgroundImage>
  )
}

const ReveWrapper = styled.div`
  z-index: 9;
  position: relative;
  .ant-typography {
    color: #fff;
  }
`
const ReveFilter = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
`

export default ReveFrancais
