import React, { useRef } from "react"
import styled from "styled-components"
import { Button, Row, Col } from "antd"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../../htmlSerializer"
import { Section } from "../stylingTools"

const Presentation = ({ content }) => {
  return (
    <div ref={useRef(null)} id="presentation">
      <Section>
        <div className="myContainer">
          <Row>
            <Col span={24}>
              <RichText
                render={content.s1_contenu.raw}
                htmlSerializer={htmlSerializer}
              />
            </Col>
            <Col span={24}>
              <CubeGrid>
                {content.s1_repeteur.map((value, index) => (
                  <div
                    className={index % 2 === 0 ? "cube c1" : "cube c2"}
                    key={index}
                  >
                    <div>
                      <RichText
                        render={value.s1_repeteur_contenu.raw}
                        htmlSerializer={htmlSerializer}
                      />
                    </div>
                  </div>
                ))}
              </CubeGrid>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a
                  href={content.s1_bouton.url}
                  rel="noopener noreferrer"
                  target={content.s1_bouton.target}
                >
                  <Button size="large" type="primary">
                    Notre appel
                  </Button>
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Section>
    </div>
  )
}

const CubeGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px 0;

  .cube {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #24b6ae;
    padding: 1.2em;
    margin: 0 20px;
    @media (max-width: 789px) {
      margin: 30px 0;
      min-width: calc(50% - 60px);
    }
    @media (max-width: 512px) {
      margin: 0;
      min-width: 100%;
      border: 0;
    }
  }

  @media (min-width: 512px) {
    .c1 {
      border-top: 2px solid #24b6ae;
    }

    .c2 {
      border-bottom: 2px solid #24b6ae;
    }
  }
`

export default Presentation
